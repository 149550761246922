import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image"
import "./generalcards.scss";

const MAX_LENGTH = 250;

export default function LargeTextCard(props) {

  return (
    <Link to={props.link} className="linkCard">
      <div className="blogCard">
        {props.image && <Img fluid={props.image} alt={props.imgAlt ? props.imgAlt : ""} className="blogCardImage" />}
        <div className="blogCardTextSection">
          <div className="tag"
            style={{backgroundColor: props.tag === "kemi" ? "#85EC74" : props.tag === "fysik" ? "#71E5FF" : "#FFB571"}}
            >
            {props.tag}
          </div>
          <h4>{props.title}</h4>
          <p>{props.text}</p>
          <button className="btnStyle">Läs mer →</button>
        </div>
      </div>
    </Link>
  );
}
