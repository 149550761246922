import React,{useEffect} from "react";
import QuizComponent from "../Quiz/QuizComponent";

export default function SubBar({ quiz }) {

  useEffect(()=> {
    if (typeof window !== "undefined") {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  },[])

  return (
    <div className="asideWrapper">
      {!quiz && (
        <>
          <h5>Hej kära läsare! 🤓</h5>
          <p>
            Välkommen till Pluggdriven.se! En plats för dig som vill få
            pluggtips och förbättra din studieteknik.
          </p>
          <hr />
          <h5>Vårt fokus</h5>
          <p>
            Vi vill göra ditt pluggande enklare genom att samla all information
            på ett och samma ställe. Genom vår sida hoppas vi kunna hjälpa dig
            med dina studiemål.
          </p>
          <p>
            Vi som ligger bakom sidan är studenter och har ett stort
            intresse för att maximera vår tid och våra studier.
          </p>
          <hr/>
          <div className="stickySidebar">
          <ins class="adsbygoogle"
          style="display:block"
          data-ad-client="ca-pub-6412000641238277"
          data-ad-slot="4401526850"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
          </div>


          <br />
        </>
      )}
      {quiz && (
        <>
          {<QuizComponent quiz={quiz} mode={"sidebar"} />}
        </>
      )}
    </div>
  );
}
